<template>
  <div class="collection-card-traits">
    <div
      v-for="trait in items"
      :key="trait.name"
      class="collection-card-traits-item"
    >
      <div class="collection-card-traits-name">
        {{ trait.name }}
      </div>
      <div class="collection-card-traits-value">
        {{ trait.value }}
      </div>
    </div>
  </div>
</template>
<script>
import { computed, defineComponent } from "vue";

export default defineComponent({
    props: {
        traits: {
            type: Object,
            default: () => ({})
        }
    },
    setup(props) {
        const items = computed(() => {
            return Object.keys(props.traits).map(trait => {
                return {
                    name: trait,
                    value: props.traits[trait]
                }
            })
        })
        return { items }
    }
})
</script>
<style lang="scss">
.collection-card-traits {
    overflow: auto;
    padding: 5px 0;
    &-item {
        box-sizing: border-box;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 5px 20px;
        border-bottom: 1px solid var(--border);
        &:first-child {
            border-top: 1px solid var(--border);
        }
    }
    &-name {
        margin-right: 10px;
        flex: 1;
        opacity: 0.5;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
        text-align: right;
    }

    &-value {
        flex: 1;
        font-size: 12px;
    }
}
</style>