<template>
  <div class="collection-card-header">
    <div class="collection-card-header-row">
      <span
        v-if="name"
        class="collection-card-header-name"
      >{{ name }}</span>
      <span
        v-if="id"
        class="collection-card-header-id"
      >#{{ id }}</span>
      <span
        v-if="guild"
        class="collection-card-header-guild"
      >{{ guild }}</span>
      <span
        v-if="rank"
        class="collection-card-header-rank"
      >RANK {{ rank }}</span>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
    inheritAttrs: false,
    props: {
        name: String,
        id: [String, Number],
        guild: String,
        rank: Number,
    }
})
</script>
<style lang="scss">
.collection-card-header {
    position: relative;
    &-row {
        display: flex;
        justify-content: space-between;
        margin: 10px 0;
    }

    &-name {
        font-weight: bold;
    }
    &-rank {
        color: var(--primary);
        font-weight: bold;
    }

    &-id {
        font-weight: bold;
        word-break: break-all;
    }
    &-guild {
        text-transform: uppercase;
        color: #3ea7ff;
        font-weight: bold;
    }

}
</style>