<template>
  <div class="collection-card-image" :class="{ 'traits': showTraits }" @click="onClick">
    <div class="collection-card-image-front">
      <img :src="image" class="collection-card-image-img" width="512" height="512" loading="lazy">
      <div
        v-if="claimableMeth"
        class="collection-card-image-claimable-meth"
      >
+{{ claimableMeth }} METH
</div>
      <div v-if="attachedToken" class="collection-card-image-attached-ape">
        <img :src="attachedToken.image">
        <span>#{{ attachedToken.originalId || attachedToken.id }}</span>
        <span
          v-if="attachedTokenMeth"
          class="collection-card-image-attached-ape-meth"
        >+{{ attachedTokenMeth }} METH/day</span>
        <!-- <svgChain width="14"/> -->
      </div>
      <div
        v-if="isOffChain"
        v-tooltip="{ content: 'Offchain token', distance: 10 }"
        class="collection-card-image-label-offchain"
      >
        <IOffChain width="18" />
      </div>
      <CollectionCardCounts
        :on-chain-count="onChainCount"
        :off-chain-count="offChainCount"
        v-if="onChainCount > 0 || offChainCount > 0"
      />
      <slot name="front" />
    </div>

    <div class="collection-card-image-back">
      <slot name="back">
        <CollectionCardTraits :traits="traits" />
      </slot>
    </div>
    <div v-if="isSelected" class="collection-card-image-selected">
      <svgCheckmark width="50" />
    </div>

    <slot />
  </div>
</template>
<script>import { defineComponent, ref } from "vue-demi";
import CollectionCardTraits from "./CollectionCardTraits.vue";
import svgCheckmark from "@/assets/icons/checkmark.svg?inline"
import IOffChain from "@/assets/icons/off-chain.svg?inline"
import CollectionCardCounts from "./CollectionCardCounts.vue";
export default defineComponent({
  components: { CollectionCardTraits, svgCheckmark, IOffChain, CollectionCardCounts },
  inheritAttrs: false,
  props: {
    image: String,
    claimableMeth: Number,
    onChainCount: [Number, String],
    offChainCount: [Number, String],
    traits: {
      type: Object,
      default: () => ({})
    },
    isSelectionMode: Boolean,
    isSelected: Boolean,
    attachedToken: Object,
    attachedTokenMeth: Number,
    isOffChain: Boolean
  },
  setup(props, { emit }) {
    const showTraits = ref(false)
    const toggleTraits = () => {
      showTraits.value = !showTraits.value
    }
    const onClick = () => {
      if (props.isSelectionMode) {
        emit('select')
      } else {
        toggleTraits()
      }

    }
    return { showTraits, toggleTraits, onClick }
  }
})
</script>
<style lang="scss">
.collection-card-image {
  position: relative;
  cursor: pointer;
  perspective: 1000px;
  &-img {
    border-radius: 10px;
    display: block;
    width: 100%;
    height: auto;
    max-width: 100%;
    object-fit: cover;
  }
  &-front {
    transition: 0.3s linear;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  &-label-offchain {
    position: absolute;
    top: 6px;
    right: 6px;
    color: var(--primary);
    border-radius: 50%;
    width: 25px;
    height: 25px;
    border: 1px solid var(--border);
    background-color: var(--gray-light);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-claimable-meth {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: var(--yellow);
    z-index: 5;
  }
  &-attached-ape {
    position: absolute;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    top: -9px;
    font-size: 14px;
    z-index: 5;
    display: flex;
    align-items: center;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.5);
    white-space: nowrap;
    img {
      width: 30px;
      border-radius: 50%;
      margin-right: 4px;
      border: 1px solid var(--border);
    }
    &-meth {
      margin-left: 5px;
      font-size: 12px;
      color: var(--purple);
    }
  }
  &-selected {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    svg {
      box-sizing: border-box;
      border: 1px solid var(--green);
      border-radius: 50%;
      color: #fff;
      background-color: var(--green);
      width: 50px;
      height: 50px;
      padding: 10px;
    }
  }
  &-back {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;

    color: #fff;
    text-align: left;
    border-radius: 10px;
    z-index: 20;
    transition: 0.3s linear;
    transform: rotateY(-180deg);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    opacity: 0;
  }
  &.traits & {
    &-front {
      transform: rotateY(180deg);
    }
    &-back {
      transform: rotateY(0);
      opacity: 1;
    }
  }
}
</style>